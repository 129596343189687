
export const applyTransparency = (color: COLORS | string, transparencyFloat: number) => {
    if (transparencyFloat < 0 || transparencyFloat > 1) {
        return color;
    }

    let transparencyHex = Math.floor(transparencyFloat * 255).toString(16);
    if (transparencyHex.length === 1) {
        transparencyHex = '0' + transparencyHex;
    }

    return color + transparencyHex;
};

export enum COLORS {
    greenishBlue = '#00BCA6',
    declineColor = '#FC4A71',
}
