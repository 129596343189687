import { mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { isMobile, isMobileApp } from '@mightybyte/rnw.utils.device-info';
import React, { useReducer, useState } from 'react';
import { Image, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { applyTransparency } from '../constants/colors';
import { AppleLogo } from '../resources/svg/AppleLogo';
import { EyeClosedIcon } from '../resources/svg/EyeClosedIcon';
import { EyeIcon } from '../resources/svg/EyeIcon';
import { GoogleLogo } from '../resources/svg/GoogleLogo';
import Logo from '../resources/svg/Logo';
import { imageUtils } from '../utils/imageUtils';
import { MB_customWindowForWeb } from '@mightybyte/rnw.utils.custom-window-for-web';

const openExternalWindow = (url: string, name?: string, width?: number, height?: number) => {
    if (isMobileApp) {
        return;
    }

    const w = MB_customWindowForWeb.outerWidth - (width ?? 600);
    const h = MB_customWindowForWeb.outerHeight - (height ?? 750);
    const left = Math.round(MB_customWindowForWeb.screenX + w / 2);
    const top = Math.round(MB_customWindowForWeb.screenY + h / 2.5);

    MB_customWindowForWeb.open(
        url,
        name ?? 'LogIn',
        'width=' + (width ?? 600) + ',height=' + (height ?? 750) + ',left=' + left + ',top=' + top + ',toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0',
    );
};

const Home = () => {

    const [ name, setName ] = useState('First & Last Name');
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');

    const [ isPasswordVisible, togglePasswordVisibility ] = useReducer((prev: boolean) => !prev, false);

    const [ isSignUpComplete, setSignUpIsComplete ] = useState(false);

    if (isMobile) {
        throw Error('Unsupported Device');
    }

    const onSignUp = () => {
        if (password.length < 8) {
            mbShowPopUp({
                title: 'Error',
                message: 'Something went wrong, please try again!',
            });
            return;
        }
        if (name.length > 0 && email.length > 0) {
            setTimeout(() => {
                setSignUpIsComplete(true);
            }, 8000);
        }
    };

    if (isSignUpComplete) {
        return (
            <View style={styles.rightSide}>
                <Text style={styles.rightSideTitle}>The sign up is completed successfully</Text>
            </View>
        );
    }

    return (
        <View style={styles.container}>
            <View style={styles.leftSide}>
                <Image source={imageUtils.HomeBackground} style={styles.leftBackground} />
                <LinearGradient
                    style={styles.leftForground}
                    colors={['#000000', '#000000', applyTransparency('#000000', 0)]}
                    //start={{ x: 0, y: 0 }} end={{ x: 0, y: 1 }}
                    locations={[0, 0.7, 1]}
                >
                    <Text style={styles.leftTitle}>It’s time to get your stuff signed by your favorite <Text style={{ color: '#38EF7D' }}>celebrity!</Text></Text>
                </LinearGradient>
                <View style={styles.leftLogo}>
                    <Logo />
                </View>
            </View>
            <View style={styles.rightSide}>
                <Text style={styles.rightSideTitle}>Sign Up to <Text style={{ color: '#38EF7D' }}>SelebSign</Text></Text>
                <View>
                    <Text style={styles.rightSideFieldName}>Name</Text>
                    <View style={styles.rightSideFieldInput}>
                        <TextInput
                            placeholder="First & Last Name"
                            style={{ color: '#8495A6', width: 400 }}
                            value={name}
                            onChangeText={setName}
                            ref={ref => ref?.setNativeProps({ tabIndex: -1 })}
                        />
                    </View>
                </View>
                <View>
                    <Text style={styles.rightSideFieldName}>Email</Text>
                    <View style={styles.rightSideFieldInput}>
                        <TextInput
                            style={{ color: '#8495A6', width: 400 }}
                            placeholder="jane@example.com"
                            ref={ref => ref?.setNativeProps({ tabIndex: -1 })}
                            value={email}
                            onChangeText={setEmail}
                        />
                    </View>
                </View>
                <View>
                    <Text style={styles.rightSideFieldName}>Password</Text>
                    <View style={styles.rightSideFieldInput}>
                        <TextInput
                            style={{ color: '#8495A6', width: 400 }}
                            placeholder="Password"
                            secureTextEntry={!isPasswordVisible}
                            ref={ref => ref?.setNativeProps({ tabIndex: -1 })}
                            value={password}
                            onChangeText={setPassword}
                        />
                        <TouchableOpacity style={styles.passwordEye} onPress={togglePasswordVisibility}>
                            { isPasswordVisible ? <EyeIcon /> : <EyeClosedIcon /> }
                        </TouchableOpacity>
                    </View>
                </View>
                <LinearGradient
                    colors={['#11998E', '#38EF7D']}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                    style={{ marginTop: 12, borderRadius: 6 }}
                >
                    <TouchableOpacity style={styles.rightSideBtn} onPress={onSignUp}>
                        <Text style={{ color: '#FFFFFF' }}>Sign Up</Text>
                    </TouchableOpacity>
                </LinearGradient>
                <View style={styles.rightSideSignUp}>
                    <View style={styles.line} />
                    <Text style={{ color: '#8495A6', marginHorizontal: 14 }}>Or Sign Up With</Text>
                    <View style={styles.line} />
                </View>
                <View style={styles.socialContainer}>
                    <TouchableOpacity style={styles.socialBtn} disabled>
                        <Text style={{ color: '#FFFFFF' }}>Google</Text>
                        <View style={styles.socialBtnLogo}>
                            <GoogleLogo />
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.socialBtn} onPress={() => openExternalWindow('https://appleid.apple.com/sign-in')}>
                        <Text style={{ color: '#FFFFFF' }}>Apple</Text>
                        <View style={styles.socialBtnLogo}>
                            <AppleLogo />
                        </View>
                    </TouchableOpacity>
                </View>
                <Text style={styles.signUpNote}>By signing up. you agree to our <Text style={{ color: '#11998E', textDecorationLine: 'underline' }}>Terms of Use</Text> and <Text style={{ color: '#11998E', textDecorationLine: 'underline' }}>Privacy Policy</Text> and to receive emails & updates from SelebSign.</Text>
            </View>
        </View >
    );
};

export { Home };

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    leftSide: {
        flex: 1,
        backgroundColor: '#000000',
    },
    leftBackground: {
        width: '100%',
        aspectRatio: 1,
        height: '70%',
        position: 'absolute',
        bottom: 0,
    },
    leftForground: {
        width: '100%',
        height: '50%',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 61,
    },
    leftTitle: {
        fontSize: 28,
        color: '#FFFFFF',
        textAlign: 'center',
    },
    leftLogo: {
        position: 'absolute',
        top: 25,
        left: 21,
    },
    rightSide: {
        flex: 1,
        backgroundColor: '#141526',
        alignItems: 'center',
        justifyContent: 'center',
    },
    rightSideTitle: {
        fontSize: 24,
        color: '#FFFFFF',
        textAlign: 'center',
        marginBottom: 27,
    },
    rightSideFieldName: {
        color: '#8495A6',
        marginBottom: 7,
    },
    rightSideFieldInput: {
        height: 52,
        borderColor: applyTransparency('#FFFFFF', 0.18),
        borderWidth: 1,
        width: 325,
        backgroundColor: applyTransparency('#FFFFFF', 0.04),
        borderRadius: 6,
        color: '#8495A6',
        paddingLeft: 15,
        marginBottom: 12,
        justifyContent: 'center',
    },
    rightSideBtn: {
        height: 52,
        width: 325,
        alignItems: 'center',
        justifyContent: 'center',
    },
    rightSideSignUp: {
        flexDirection: 'row',
        marginVertical: 21,
        alignItems: 'center',
    },
    line: {
        width: 74,
        height: 1,
        backgroundColor: '#5E5E5E',
    },
    socialBtn: {
        width: 158,
        height: 52,
        borderColor: applyTransparency('#FFFFFF', 0.18),
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: applyTransparency('#FFFFFF', 0.04),
        borderRadius: 8,
    },
    socialContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 325,
    },
    socialBtnLogo: {
        position: 'absolute',
        height: '100%',
        justifyContent: 'center',
        left: 14,
    },
    signUpNote: {
        fontSize: 10,
        color: '#DDDDDD',
        width: 325,
        marginTop: 20,
        textAlign: 'center',
    },
    passwordEye: {
        position: 'absolute',
        right: 13,
        height: '100%',
        justifyContent: 'center',
    },
});
