//import 'react-native-gesture-handler';

import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { isMobile, MB_deviceInfoInitialize } from '@mightybyte/rnw.utils.device-info';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import { MB_Toast } from '@mightybyte/rnw.components.toast';
import { MB_ModalAttachGlobalChildren } from '@mightybyte/rnw.components.modal';
import { MB_orientationLocker, MB_ORIENTATION_LOCK } from '@mightybyte/rnw.utils.orientation-locker';
import { Home } from './src/screens/Home';
import { MB_PopUp, MB_PopUpSetDefaultStyles } from '@mightybyte/rnw.components.pop-up';

async function initialSetup() {
  return new Promise<void>(async (resolve) => {
    await MB_deviceInfoInitialize();
    MB_PopUpSetDefaultStyles({
      defaultContainerStyle: styles.modalPopUpDefaultContainer,
      defaultTitleStyle: styles.modalPopUpDefaultTitle,
      defaultMessageStyle: styles.modalPopUpDefaultMessage,
      defaultPrimaryButtonStyle: styles.modalPopUpDefaultPrimaryButton,
      defaultSeconaryButtonStyle: styles.modalPopUpDefaultSecondaryButton,
      defaultButtonsContainerStyleModifier: styles.modalPopUpDefaultButtonsContainerModifier,
    });

    MB_orientationLocker.setDefaultOrientation(MB_ORIENTATION_LOCK.DEFAULT, true);

    MB_ModalAttachGlobalChildren([
      <MB_Toast key={'toast'} />,
    ]);


    resolve();
  });
}

const App = () => {
  const [isLoadingComplete, setIsLoadingComplete] = useState(false);

  useEffect(() => {
    initialSetup().finally(() => setIsLoadingComplete(true));
  }, []);

  if (!isLoadingComplete) {
    return null;
  }

  return (
    <SafeAreaProvider>
      <SafeAreaView style={styles.container}>
        <Home />
        <MB_PopUp key={'pop-up'} />
      </SafeAreaView>
    </SafeAreaProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  modalPopUpDefaultContainer: {
    backgroundColor: isMobile ? '#000000' : '#1D1A31',
    borderRadius: 10,
    paddingTop: isMobile ? 33 : 40,
    paddingHorizontal: isMobile ? 18 : 76,
    paddingBottom: isMobile ? 32 : 44,
    marginHorizontal: 16,
  },
  modalPopUpDefaultTitle: {
    fontSize: 28,
    color: '#FFFFFF',
    textAlign: 'center',
  },
  modalPopUpDefaultMessage: {
    fontSize: 16,
    marginTop: isMobile ? 10 : 18,
    color: '#BAC0CA',
    textAlign: 'center',
  },
  modalPopUpDefaultPrimaryButton: {
    height: isMobile ? 40 : 58,
    minWidth: isMobile ? 144 : 168,
    alignSelf: 'center',
    backgroundColor: '#9A4C95',
  },
  modalPopUpDefaultSecondaryButton: {
    height: isMobile ? 40 : 58,
    minWidth: isMobile ? 144 : 168,
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#B8ABBA',
  },
  modalPopUpDefaultButtonsContainerModifier: {
    marginTop: isMobile ? 36 : 48,
  },
});

export default App;
