import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const EyeIcon = () => {
    return (
        <Svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
        >
            <Path
                d="M.667 8S3.333 2.667 8 2.667 15.333 8 15.333 8 12.667 13.333 8 13.333.667 8 .667 8z"
                stroke="#8495A6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <Path
                d="M8 10a2 2 0 100-4 2 2 0 000 4z"
                stroke="#8495A6"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
};

export { EyeIcon };
