import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const AppleLogo = () => {
  return (
    <Svg
      width={20}
      height={24}
      viewBox="0 0 20 24"
      fill="none"
    >
      <Path
        d="M19.581 18.703a13.08 13.08 0 01-1.277 2.321c-.671.967-1.22 1.637-1.644 2.009-.656.61-1.36.923-2.113.94-.542 0-1.193-.155-1.953-.471-.761-.314-1.461-.47-2.101-.47-.672 0-1.392.157-2.162.47-.771.316-1.392.48-1.867.497-.723.031-1.443-.29-2.162-.966-.458-.405-1.032-1.098-1.72-2.08C1.845 19.904 1.24 18.688.764 17.3.255 15.804 0 14.353 0 12.95 0 11.34.344 9.952 1.032 8.79A6.09 6.09 0 013.196 6.58a5.77 5.77 0 012.924-.834c.574 0 1.326.18 2.261.531.932.354 1.531.534 1.794.534.196 0 .861-.21 1.989-.629 1.066-.387 1.966-.548 2.703-.484 1.998.163 3.498.96 4.497 2.392-1.788 1.095-2.67 2.628-2.653 4.593.016 1.532.565 2.807 1.645 3.818.49.47 1.037.833 1.645 1.09-.131.387-.271.757-.419 1.112zM15 .481c0 1.2-.434 2.321-1.298 3.358-1.043 1.233-2.306 1.946-3.675 1.833A3.789 3.789 0 0110 5.216c0-1.152.496-2.384 1.377-3.392.44-.511 1-.936 1.678-1.273.678-.335 1.319-.519 1.921-.551A4.8 4.8 0 0115 .48l-.001.001z"
        fill="#fff"
      />
    </Svg>
  );
};

export { AppleLogo };
